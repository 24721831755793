import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faComment,
  faPen,
  faStickyNote,
  faPhone,
  faUser,
  faStopwatch,
  faCheck,
  faBell,
  faEnvelope,
  faComments,
  faMoneyCheckAlt,
  faEye,
  faMoneyBillWave,
  faLevelDownAlt,
  faBan,
  faLevelUpAlt,
  faPlus,
  faPencilAlt,
  faUserTag,
  faUserTimes,
  faTicketAlt,
  faUserCircle,
  faBuilding,
  faRedo,
  faUserFriends,
  faPiggyBank,
  faMoneyCheck,
  faChartLine,
  faUserEdit,
  faPenNib,
  faLaptopMedical,
  faHandshake,
  faLink,
  faExclamationCircle,
  faHandHoldingUsd,
  faCommentsDollar,
  faSms,
  faCommentDollar,
  faTasks,
  faUndo,
  faShare,
  faFileInvoiceDollar,
  faProjectDiagram,
  faUpload,
  faTimes,
  faFunnelDollar,
  faPowerOff,
  faPaperPlane,
  faSkull,
  faCalculator,
  faUserNurse,
  faArrowsAltV,
  faScroll,
  faShoePrints,
  faCheckDouble,
  faSpinner,
  faArrowDown,
  faArrowUp,
  faFilePowerpoint,
  faClipboardCheck,
  faPrint,
  faHourglassEnd,
  faCalendarCheck,
  faDownload, faExclamationTriangle,
  faHeadset,
  faFolderOpen,
  faPaste,
  faClock,
  faFileExcel,
  faFilter,
  faSortDown,
  faSortUp,
  faPhoneAlt,
  faCoins,
  faSignOutAlt,
  faFile,
  faHotel,
  faTrash,
  faUserPlus,
  faBars,
  faMinusCircle,
  faEdit,
  faCalendarAlt,
  faRetweet,
  faMotorcycle

} from '@fortawesome/free-solid-svg-icons'

import {
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const fa = [
  faComment,
  faPen,
  faStickyNote,
  faPhone,
  faUser,
  faStopwatch,
  faCheck,
  faWhatsapp,
  faBell,
  faEnvelope,
  faComments,
  faMoneyCheckAlt,
  faEye,
  faMoneyBillWave,
  faLevelDownAlt,
  faBan,
  faLevelUpAlt,
  faPlus,
  faPencilAlt,
  faUserTag,
  faUserTimes,
  faTicketAlt,
  faUserCircle,
  faBuilding,
  faFilePowerpoint,
  faRedo,
  faUserFriends,
  faPiggyBank,
  faMoneyCheck,
  faChartLine,
  faUserEdit,
  faPenNib,
  faLaptopMedical,
  faHandshake,
  faLink,
  faExclamationCircle,
  faHandHoldingUsd,
  faCommentsDollar,
  faSms,
  faCommentDollar,
  faTasks,
  faUndo,
  faShare,
  faFileInvoiceDollar,
  faProjectDiagram,
  faUpload,
  faTimes,
  faFunnelDollar,
  faPowerOff,
  faPaperPlane,
  faSkull,
  faCalculator,
  faUserNurse,
  faArrowsAltV,
  faScroll,
  faShoePrints,
  faCheckDouble,
  faSpinner,
  faArrowDown,
  faArrowUp,
  faClipboardCheck,
  faPrint,
  faHourglassEnd,
  faCalendarCheck,
  faDownload,
  faExclamationTriangle,
  faHeadset,
  faFolderOpen,
  faStickyNote,
  faPaste,
  faClock,
  faFileExcel,
  faFilter,
  faSortDown,
  faSortUp,
  faPhoneAlt,
  faCoins,
  faSignOutAlt,
  faFile,
  faHotel,
  faTrash,
  faUserPlus,
  faBars,
  faMinusCircle,
  faEdit,
  faCalendarAlt,
  faRetweet,
  faMotorcycle
]

fa.some(icon => {
  library.add(icon)
})


Vue.component('font-awesome-icon', FontAwesomeIcon)
